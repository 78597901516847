.theo-primary-color,
.vjs-selected {
  color: #00a2ff !important;
}

.theo-primary-background {
  color: #000000 !important;
  background-color: #00a2ff !important;
}

.theo-secondary-color {
  color: #ffffff !important;
}

.theo-secondary-background {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.theo-tertiary-color {
  color: #000000 !important;
}

.theo-tertiary-background {
  color: #ffffff !important;
  /*background-color: #ffffff !important;*/
}

.video-js .theo-player-wrapper {
  background-color: #f8f8f8 !important;
}

.theo-control-bar-shadow {
  border-radius: 12px;
  overflow: hidden;
}

.theo-menu-animation-container {
  z-index: 1;
}

.theoplayer-skin {
  border-radius: 12px;
}

/*
.theoplayer-skin { 
	background-color: #ffffff !important;
}*/

.theoplayer-skin .vjs-big-play-button:after {
  content: '';
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMTI4MC4wMDAwMDBwdCIgaGVpZ2h0PSIxMjgwLjAwMDAwMHB0IiB2aWV3Qm94PSIwIDAgMTI4MC4wMDAwMDAgMTI4MC4wMDAwMDAiCiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0Ij4KPG1ldGFkYXRhPgpDcmVhdGVkIGJ5IHBvdHJhY2UgMS4xNSwgd3JpdHRlbiBieSBQZXRlciBTZWxpbmdlciAyMDAxLTIwMTcKPC9tZXRhZGF0YT4KPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsMTI4MC4wMDAwMDApIHNjYWxlKDAuMTAwMDAwLC0wLjEwMDAwMCkiCmZpbGw9IiMwMDAwMDAiIHN0cm9rZT0ibm9uZSI+CjxwYXRoIGQ9Ik00NjEwIDYzOTkgbDAgLTI4ODEgNDMgMjUgYzE5NSAxMTQgNDE0NCAyMzkyIDQ0OTQgMjU5MyAzMzkgMTk0IDQ0OAoyNjIgNDQwIDI3MCAtNyA3IC03NDMgNDM0IC0xNjM3IDk0OSAtODk0IDUxNiAtMjAwMSAxMTU1IC0yNDYwIDE0MjAgLTQ1OSAyNjUKLTg0NSA0ODcgLTg1NyA0OTQgbC0yMyAxMiAwIC0yODgyeiIvPgo8L2c+Cjwvc3ZnPgo=')
    no-repeat 0 0;
  background-size: 100%;
  display: block;
}

.theo-big-play-button-svg-container svg {
  display: none;
}

.theoplayer-skin li[aria-label='Open the video speed settings menu'] {
  display: none !important;
}

.theoplayer-skin .vjs-control-bar {
  z-index: 1 !important;
}
